<template>
<div class="container">
  <h1>APE Stadel Artikel Manager</h1>
  <article-manager />
</div>
</template>

<script>
import ArticleManager from './components/ArticleManager.vue'

export default {
  name: 'App',
  components:{'article-manager':ArticleManager}
}
</script>

<style>
</style>
