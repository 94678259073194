import axios from 'axios';
const URL = "https://apestadel.de";
export default {
    async getToken(user, pass) {
        const data = {
            "client_id": "administration",
            "grant_type": "password",
            "scopes": "write",
            "username": user,
            "password": pass
        }
        return await axios.post(URL + '/api/oauth/token', data)
            .then(response => response.data)
            .catch(error => error.response.data);
    },

    async getHeaders(auth_data, additionalHeaders = {}) {
        return { Authorization: 'Bearer ' + auth_data.access_token, ...additionalHeaders };
    },
    async refreshToken(refreshToken) {
        return await axios.post(URL + '/api/oauth/token', {
            "grant_type": "refresh_token",
            "client_id": "administration",
            "refresh_token": refreshToken
        }).then(response => response.data)
            .catch(() => {
                return {
                    token_type: null,
                    expires_in: null,
                    access_token: null,
                    refresh_token: null,
                }
            });
    }
}