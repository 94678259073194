<template>
  <tr ref="targetEl" :class="{ invisible: !shouldRender }">
    <template v-if="shouldRender">
      <td>{{ product.productNumber }}</td>
      <td>{{ product.name }}</td>
      <td>
        <span
          v-if="
            product.customFields &&
            product.customFields.articleManagerData &&
            product.customFields.articleManagerData.kleinanzeigen
          "
          >&check;</span
        >
        <span v-else>&times;</span>
      </td>
      <td>
        <span v-if="product.active">&check;</span>
        <span v-else>&times;</span>
      </td>
      <td>
        <span :style="{ color: stockColor }">&#9632;</span>
        {{ Number(product.stock) }}
      </td>
      <td
        v-if="
          product.customFields != null &&
          product.customFields.articleManagerData != null
        "
      >
        {{ Number(product.customFields.articleManagerData.minStock) }}
      </td>
      <td v-else>Nicht gesetzt</td>
      <td>
        {{ orderedCount }}
      </td>
      <td>{{backorderCount}}</td>
      <td>
        <span :style="{ color: nOverNColor }">{{ nOverN }}</span>
      </td>
      <td>{{ product.price[0].gross.toFixed(2) }} €</td>
      <td>
        <button
          class="btn btn-sm btn-primary"
          @click.prevent="setSelectedProduct(product)"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
        >
          &#9998;
        </button>
      </td>
    </template>
  </tr>

  <tr></tr>
</template>

<script>
import { useIntersectionObserver } from "@vueuse/core";

export default {
  emits: ["selectedProductChanged"],
  props: ["product"],
  data() {
    return {
      shouldRender: false,
      config: {
        trType: "tr",
        trShouldUnload: true,
      },
    };
  },
  computed: {
    stockColor() {
      const green = "#0f0";
      const red = "#f00";
      const yellow = "yellow";

      const stock = this.product.stock;
      if (
        this.product.customFields &&
        this.product.customFields.articleManagerData
      ) {
        const minStock = this.product.customFields.articleManagerData.minStock;
        if (stock > minStock) {
          return green;
        } else if (stock <= minStock && stock > Number(minStock) / 2) {
          return yellow;
        } else if (stock <= Number(minStock) / 2) {
          return red;
        } else {
          return "#000";
        }
      } else {
        return green;
      }
    },
    orderedCount() {
      if (
        this.product.customFields &&
        this.product.customFields.articleManagerData &&
        this.product.customFields.articleManagerData.ordered
      ) {
        return this.product.customFields.articleManagerData.ordered.reduce(
          (sum, order) => {
            return (sum += Number(order.count));
          },
          0
        );
      } else {
        return 0;
      }
    },
    backorderCount() {
      if(this.product.customFields &&
      this.product.customFields.articleManagerData &&
      this.product.customFields.articleManagerData.backorder) {
        return this.product.customFields.articleManagerData.backorder;
      } else {
        return 0;
      }
    },
    nOverN() {
      if(this.product.customFields &&
          this.product.customFields.articleManagerData &&
          this.product.customFields.articleManagerData.vendorPrices.length > 0) {
        // Getting net price from brut price
        const netPrice = this.product.price[0].gross / 1.19;
        // Getting net purchase price
        const vendorPricesSum = this.product.customFields.articleManagerData.vendorPrices.reduce(
            (sum, curPrice) => {
              return Number(sum) + Number(curPrice.price);
            },
            0
        );

        const vendorPricesAvg =
            vendorPricesSum / this.product.customFields.articleManagerData.vendorPrices.length;

        return (netPrice / vendorPricesAvg).toFixed(1);
      }
      else {
        return 0;
      }
    },
    nOverNColor() {
      if(this.nOverN <= 1.5) {
        return "#f00";
      } else if (this.nOverN > 1.5 && this.nOverN <= 2) {
        return "orange";
      } else {
        return "#000";
      }
    }
  },
  methods: {
    setSelectedProduct(product) {
      this.$emit("selectedProductChanged", product);
    },
  },
  mounted() {
    this.targetEl = this.$refs.targetEl;
    useIntersectionObserver(
      this.targetEl,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          this.shouldRender = true;
        } else {
          this.shouldRender = false;
        }
      },
      { rootMargin: "50px" }
    );
  },
};
</script>

<style scoped>
.invisible {
  height: 35px;
}
</style>
