<template>
  <div
    class="modal fade"
    id="myModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ product.name }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-6">
              <b>Nummer:</b>
            </div>
            <div class="col-sm-6">
              {{ product.productNumber }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b>Beschreibung:</b><br />
              <div
                v-html="product.description"
                style="
                  overflow: scroll;
                  max-height: 150px;
                  background-color: #eee;
                "
              ></div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b>Bestand:</b>
            </div>
            <div class="col-sm-4">
              <input type="number" v-model="stock" min="0" step="1" class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b>Mindestbestand:</b>
            </div>
            <div class="col-sm-4">
              <input
                type="number"
                class="form-control"
                v-model="articleManagerData.minStock"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b>Kleinanzeigen:</b>
            </div>
            <div class="col-sm-4">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="articleManagerData.kleinanzeigen"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b>Aktiv:</b>
            </div>
            <div class="col-sm-4">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="active"
              />
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6">
              <b>Backorder:</b>
            </div>
            <div class="col-sm-4">
              <input
                type="number"
                class="form-control"
                min="0"
                step="1"
                v-model="articleManagerData.backorder"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b>Bestellt:</b>
            </div>
            <div class="col-sm-6">
              {{ orderedSum }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button class="btn btn-sm btn-primary" @click="addOrder">
                Bestellung hinzufügen
              </button>
            </div>
          </div>
          <div
            class="row"
            v-for="order in articleManagerData.ordered"
            :key="order.key"
          >
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                v-model="order.vendor"
                placeholder="Lieferant"
                list="vendors"
              />
            </div>
            <div class="col-sm-3">
              <input
                type="number"
                class="form-control"
                v-model="order.count"
                placeholder="Anzahl"
                min="0"
                step="1"
              />
            </div>
            <div class="col-sm-1">
              <button class="btn btn-success" @click="removeOrder(order, true)">
                &check;
              </button>
            </div>
            <div class="col-sm-1">
              <button class="btn btn-danger" @click="removeOrder(order)">
                -
              </button>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6">
              <b>Herstellerpreise:</b>
            </div>
            <div class="col-sm-6">&#216; {{ vendorPriceAvg }} €</div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button class="btn btn-sm btn-primary" @click="addVendorPrice">
                Einkaufspreis hinzufügen
              </button>
            </div>
          </div>
          <div
            class="row"
            v-for="price in articleManagerData.vendorPrices"
            :key="price.key"
          >
            <div class="col-sm-3">
              <input
                type="text"
                class="form-control"
                v-model="price.vendor"
                placeholder="Lieferant"
                list="vendors"
              />
            </div>
            <div class="col-sm-3">
              <input
                  type="text"
                  class="form-control"
                  v-model="price.number"
                  placeholder="Nummer"
              />
            </div>
            <div class="col-sm-4">
              <input
                type="number"
                class="form-control"
                v-model="price.price"
                placeholder="Preis"
                min="0"
                step="any"
              />
            </div>
            <div class="col-sm-2">
              <button class="btn btn-danger" @click="removeVendorPrice(price)">
                -
              </button>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-sm-6">
              <b>APE Stadel Preis:</b>
            </div>
            <div class="col-sm-6" v-if="product.price">
              {{ Number(product.price[0].gross).toFixed(2) }} €
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <b>Konkurrenzpreise:</b>
            </div>
            <div class="col-sm-6">
              &#216; {{ compPriceAvg }} €<br />
              (Differenz:
              <span :style="{ color: getDiffColor(priceDiff) }"
                >{{ priceDiff }} €</span
              >)
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button class="btn btn-sm btn-primary" @click="addCompPrice">
                Konkurrenzpreis hinzufügen
              </button>
            </div>
          </div>
          <div
            class="row"
            v-for="price in articleManagerData.compPrices"
            :key="price.key"
          >
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                v-model="price.competitor"
                placeholder="Konkurrent"
                list="competitors"
              />
            </div>
            <div class="col-sm-4">
              <input
                type="number"
                class="form-control"
                v-model="price.price"
                placeholder="Preis"
                min="0"
                step="any"
              />
            </div>
            <div class="col-sm-2">
              <button class="btn btn-danger" @click="removeCompPrice(price)">
                -
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click.prevent="saveArticleManagerData"
            data-bs-dismiss="modal"
          >
            Speichern
          </button>
        </div>
      </div>
    </div>
  </div>
  <datalist id="competitors">
    <option
      v-for="competitor in competitors"
      :key="competitor"
      :value="competitor"
    />
  </datalist>
  <datalist id="vendors">
    <option
      v-for="vendor in vendors"
      :key="vendor"
      :value="vendor"
    />
  </datalist>
</template>

<script>
export default {
  name: "ArticleModal",
  props: ["product", "vendors", "competitors"],
  watch: {
    product: function (newValue) {
      const customFields = newValue.customFields;
      this.stock = newValue.stock;
      this.active = newValue.active;
      if (customFields) {
        if (customFields.articleManagerData) {
          const tempArticleManagerData = customFields.articleManagerData;
          this.articleManagerData = {
            minStock: tempArticleManagerData.minStock
              ? tempArticleManagerData.minStock
              : 0,
            backorder: tempArticleManagerData.backorder
              ? tempArticleManagerData.backorder
              : 0,
            kleinanzeigen: tempArticleManagerData.kleinanzeigen
              ? tempArticleManagerData.kleinanzeigen
              : false,
            ordered: tempArticleManagerData.ordered
              ? tempArticleManagerData.ordered
              : [],
            vendorPrices: tempArticleManagerData.vendorPrices
              ? tempArticleManagerData.vendorPrices
              : [],
            compPrices: tempArticleManagerData.compPrices
              ? tempArticleManagerData.compPrices
              : [],
          };
        }
      } else {
        this.articleManagerData = {
          minStock: 0,
          backorder: 0,
          kleinanzeigen: false,
          ordered: [],
          vendorPrices: [],
          compPrices: [],
        };
      }
    },
  },
  data() {
    return {
      articleManagerData: {
        minStock: 0,
        backorder: 0,
        kleinanzeigen: false,
        ordered: [],
        vendorPrices: [],
        compPrices: [],
      },
      stock:0,
      active: false,
    };
  },
  methods: {
    saveArticleManagerData() {
      this.$emit("saveChanges", {
        id: this.product.id,
        articleManagerData: this.articleManagerData,
        stock: this.stock,
        active: this.active,
      });
    },
    addOrder() {
      this.articleManagerData.ordered.push({
        vendor: "",
        count: 0,
        key: Date.now(),
      });
    },
    removeOrder(order, approve = false) {
      const index = this.articleManagerData.ordered.indexOf(order);
      const value = this.articleManagerData.ordered[index].count;
      this.articleManagerData.ordered.splice(index, 1);
      if(approve) {
        this.stock += value;
      }
    },
    addVendorPrice() {
      this.articleManagerData.vendorPrices.push({
        vendor: "",
        price: 0,
        key: Date.now(),
      });
    },
    removeVendorPrice(VendorPrice) {
      const index = this.articleManagerData.vendorPrices.indexOf(VendorPrice);
      this.articleManagerData.vendorPrices.splice(index, 1);
    },
    addCompPrice() {
      this.articleManagerData.compPrices.push({
        vendor: "",
        price: 0,
        key: Date.now(),
      });
    },
    removeCompPrice(compPrice) {
      const index = this.articleManagerData.compPrices.indexOf(compPrice);
      this.articleManagerData.compPrices.splice(index, 1);
    },
    getDiffColor(diff) {
      if (diff <= 0) {
        return "#f00";
      } else {
        return "#0f0";
      }
    },
  },
  computed: {
    orderedSum() {
      return this.articleManagerData.ordered.reduce((sum, curOrder) => {
        return Number(sum) + Number(curOrder.count);
      }, 0);
    },
    vendorPriceAvg() {
      const vendorPricesSum = this.articleManagerData.vendorPrices.reduce(
        (sum, curPrice) => {
          return Number(sum) + Number(curPrice.price);
        },
        0
      );

      return (
        vendorPricesSum / this.articleManagerData.vendorPrices.length
      ).toFixed(2);
    },
    compPriceAvg() {
      const compPricesSum = this.articleManagerData.compPrices.reduce(
        (sum, curPrice) => {
          return Number(sum) + Number(curPrice.price);
        },
        0
      );

      return (
        compPricesSum / this.articleManagerData.compPrices.length
      ).toFixed(2);
    },
    priceDiff() {
      if (this.product && this.product.price && this.product.price[0]) {
        return (this.compPriceAvg - Number(this.product.price[0].gross)).toFixed(
          2
        );
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped>
div#myModal div.row {
  margin-bottom: 1em;
}
</style>
